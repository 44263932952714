import React from 'react';

const ContactPage = () => (
  <section className="max-w-2xl mx-auto">
    <h1 className="text-4xl font-serif font-bold mb-8 text-gray-800">Contact Us</h1>
    <p className="text-lg mb-12 text-gray-600">We'd love to hear from you! Whether you have a question about our products, need technical support, or just want to say hello, feel free to reach out.</p>
    <div className="bg-gray-50 p-8 rounded-lg shadow-md">
      <h2 className="text-2xl font-serif font-bold mb-6 text-gray-800">Get in Touch</h2>
      <p className="mb-4"><strong className="text-gray-700">Email:</strong> <a href="mailto:hi@kabett.com" className="text-blue-600 hover:underline">hi@kabett.com</a></p>
     
    </div>
  </section>
);

export default ContactPage;