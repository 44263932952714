import React from 'react';
import { Helmet } from 'react-helmet';

const BusinessConsultationPage = () => (
  <>
    <Helmet>
      <title>Kabett - Business Consultation Services by Harish Hegde</title>
      <meta name="description" content="Expert business consultation services by Harish Hegde. Transform your business with our Small Entrepreneurship Solutions program." />
    </Helmet>
    <div className="prose max-w-none">
      <h1 className="text-4xl font-serif font-bold mb-8">Business Consultation Services</h1>
      
      <div className="flex flex-col md:flex-row items-start mb-12">
      <div className="md:w-1/3 mb-6 md:mb-0 md:mr-8">
          <img 
            src="/harish-hegde-business-consultant-udupi-india.webp" 
            alt="Harish Hegde" 
            className="w-full h-auto rounded-lg"
          />
        </div>
        <div className="md:w-2/3">
          <h2 className="text-2xl font-serif font-bold mb-4">Harish Hegde - Business Consultant</h2>
          <p className="mb-4">Are you facing challenges in your business? You're not alone. Many companies come to me saying:</p>
          <ul className="list-disc pl-5 mb-6">
            <li>We have trouble attracting new prospects.</li>
            <li>Sales are down and we're not sure why.</li>
            <li>We don't appear to be competitive.</li>
            <li>We're stuck and can't figure out how to move forward.</li>
            <li>We can't attract good staff.</li>
            <li>We do not get proper advice for tax planning.</li>
            <li>We have problems with tax compliances.</li>
            <li>I have to be involved in everything every day.</li>
            <li>I cannot go on holidays. I am stuck full time in business.</li>
          </ul>
          <p>My Core Program – Small Entrepreneurship Solutions – addresses these issues and more.</p>
        </div>
      </div>

      <h2 className="text-3xl font-serif font-bold mt-12 mb-6">How I Can Help</h2>
      <p className="mb-6">I help successful sole-proprietors or partnerships (retail, online, professional services) who want to get to the next level of their business, but don't know how. To help them get there, I use several techniques and activities:</p>
      <ul className="list-disc pl-5 mb-6">
        <li>Refine the way they present themselves to attract more business.</li>
        <li>Make recommendations for specific marketing activities.</li>
        <li>Examine operations to find ways to be more efficient with resources on hand.</li>
        <li>Review Cash-flow and Income Statements to determine if the business model is strong.</li>
        <li>Redesign HR policies and restructure the organization.</li>
        <li>Create timelines to implement new objectives with specific milestones.</li>
        <li>Hold clients accountable for their goals to keep them motivated.</li>
        <li>Explore beliefs, practices, and preconceptions about pricing.</li>
      </ul>

      <h2 className="text-3xl font-serif font-bold mt-12 mb-6">The Consultation Process</h2>
      <p className="mb-4">My consultation process consists of nine key steps:</p>
      <ol className="list-decimal pl-5 mb-6">
        <li><strong>Initial Meeting:</strong> A FREE 45-minute business review session to discuss your current situation.</li>
        <li><strong>Proposal and Contract:</strong> If we decide to proceed, I'll send a detailed proposal and contract.</li>
        <li><strong>Questionnaire:</strong> You'll complete a questionnaire to help me understand your business better.</li>
        <li><strong>Goal Setting:</strong> We'll review your responses and set prioritized goals.</li>
        <li><strong>Regular Meetings:</strong> Periodic sessions to review progress and address immediate concerns.</li>
        <li><strong>Personalized Growth Plan:</strong> Creation of a plan you can use independently.</li>
        <li><strong>Customer Identification:</strong> We'll identify your ideal customers and research where to find them.</li>
        <li><strong>Core Marketing Message:</strong> Development of a powerful statement that encapsulates your value proposition.</li>
        <li><strong>Ongoing Support:</strong> The program typically runs for three months, with access to me via phone or email for specific issues.</li>
      </ol>

      <h2 className="text-3xl font-serif font-bold mt-12 mb-6">Results You Can Expect</h2>
      <p className="mb-6">After working with me, clients feel much more productive because they have a realistic road map to grow their business. This ultimately makes them more profitable and relaxed in their operations.</p>

      <div className="bg-blue-50 p-6 rounded-lg mt-12">
        <h3 className="text-xl font-serif font-bold mb-4">Ready to Take Your Business to the Next Level?</h3>
        <p className="mb-4">Contact me today for your free 45-minute business review session and let's start working towards your business goals.</p>
        <a href="#contact" className="bg-blue-600 text-white px-6 py-2 rounded-full font-semibold inline-block hover:bg-blue-700 transition-colors">Get Started</a>
      </div>
    </div>
  </>
);

export default BusinessConsultationPage;