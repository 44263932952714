import React from 'react';

const PrivacyPolicyPage = () => (
  <section className="prose max-w-none font-sans">
    <h1 className="text-4xl font-serif font-bold mb-8 text-gray-800">Privacy Policy</h1>
    <p className="text-sm text-gray-500 mb-8">Last updated: 4 July 2024</p>
    
    <p className="mb-8">At Kabett, we are committed to protecting your privacy and ensuring you have a positive experience on our websites and while using our products. This policy outlines our data handling practices for our various products.</p>
    
    <h2 className="text-2xl font-serif font-bold mt-12 mb-4 text-gray-800">1. Information We Collect</h2>
    <p className="mb-6">The type and amount of information we collect depends on how you use our products:</p>
    <ul className="list-disc pl-6 mb-8">
      <li className="mb-2"><strong>MummaCare:</strong> We use Google Analytics to collect standard internet log information and details of visitor behavior patterns. This information is processed in a way that does not identify anyone.</li>
      <li className="mb-2"><strong>Pranayama Chrome Extension:</strong> We do not collect any personal data or usage information through this extension.</li>
    </ul>
    
    <h2 className="text-2xl font-serif font-bold mt-12 mb-4 text-gray-800">2. How We Use Your Information</h2>
    <p className="mb-6">For MummaCare, we use the collected information to:</p>
    <ul className="list-disc pl-6 mb-8">
      <li className="mb-2">Analyze website usage to improve user experience</li>
      <li className="mb-2">Customize content and advertising</li>
      <li className="mb-2">Conduct research and analysis to maintain, protect, develop, and improve our products</li>
    </ul>
    <p className="mb-8">For the Pranayama Chrome Extension, as no data is collected, we do not use any personal information.</p>
    
    <h2 className="text-2xl font-serif font-bold mt-12 mb-4 text-gray-800">3. Data Sharing and Disclosure</h2>
    <p className="mb-8">We do not sell your personal information to third parties. We may share non-personally identifiable information publicly and with our partners – like publishers, advertisers, developers, or rights holders.</p>
    
    <h2 className="text-2xl font-serif font-bold mt-12 mb-4 text-gray-800">4. Data Security</h2>
    <p className="mb-8">We implement a variety of security measures to maintain the safety of your personal information when you enter, submit, or access your personal information. However, no method of transmission over the Internet, or method of electronic storage, is 100% secure.</p>
    
    <h2 className="text-2xl font-serif font-bold mt-12 mb-4 text-gray-800">5. Cookies and Tracking Technologies</h2>
    <p className="mb-8">MummaCare uses cookies and similar tracking technologies to track the activity on our service and hold certain information. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent.</p>
    
    <h2 className="text-2xl font-serif font-bold mt-12 mb-4 text-gray-800">6. Children's Privacy</h2>
    <p className="mb-8">Our services are not intended for use by children under the age of 13. We do not knowingly collect personal information from children under 13.</p>
    
    <h2 className="text-2xl font-serif font-bold mt-12 mb-4 text-gray-800">7. Changes to This Privacy Policy</h2>
    <p className="mb-8">We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last updated" date.</p>
    
    <h2 className="text-2xl font-serif font-bold mt-12 mb-4 text-gray-800">8. Contact Us</h2>
    <p className="mb-4">If you have any questions about this Privacy Policy, please contact us at:</p>
    <p className="mb-8"><strong>Email:</strong> hi@kabett.com </p>
  </section>
);

export default PrivacyPolicyPage;