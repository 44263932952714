import React, { useState, useRef, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { motion } from 'framer-motion';
import { ArrowRight } from 'lucide-react';
import PrivacyPolicyPage from './PrivacyPolicyPage';
import ContactPage from './ContactPage';
import BusinessConsultationPage from './BusinessConsultationPage';

const App = () => {
  const [currentPage, setCurrentPage] = useState('home');
  const productsRef = useRef(null);

  useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash.slice(1);
      if (hash === 'privacy-policy') {
        setCurrentPage('privacy');
      } else if (hash === 'contact') {
        setCurrentPage('contact');
      } else if (hash === 'business-consultation') {
        setCurrentPage('consultation');
      } else {
        setCurrentPage('home');
      }
    };

    handleHashChange();
    window.addEventListener('hashchange', handleHashChange);
    return () => window.removeEventListener('hashchange', handleHashChange);
  }, []);

  const scrollToProducts = () => {
    productsRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const HomePage = () => (
    <>
      <Helmet>
        <title>Kabett - Building Useful Products for Everyday Life</title>
        <meta name="description" content="Kabett creates smart, simple solutions for everyday challenges. Discover our innovative products designed to make your life easier." />
        <meta name="keywords" content="Kabett, productivity tools, health apps, wellness applications, everyday solutions" />
        <meta property="og:title" content="Kabett - Innovative Solutions for Daily Life" />
        <meta property="og:description" content="Discover Kabett's suite of products designed to enhance your daily life and productivity." />
        <meta property="og:image" content="https://kabett.com/kabett-screen.webp" />
        <meta property="og:url" content="https://kabett.com" />
      </Helmet>

      <section className="flex flex-col md:flex-row items-start justify-between mb-24">
        <div className="md:w-1/2 mb-12 md:mb-0 md:pr-12">
          <h1 className="text-4xl md:text-5xl lg:text-6xl font-serif font-bold mb-8 leading-tight">
            Building Useful Products for Everyday Life
          </h1>
          <p className="text-xl mb-8 max-w-2xl text-gray-600">
            Ever wished for tools that just make life easier? That's what we do at Kabett. We're all about creating smart, simple solutions for everyday challenges. From helping new moms navigate parenthood to bringing calm into your busy day, our products are designed with you in mind. Ready to discover how we can make your day a little bit better?
          </p>
          <motion.button
            className="bg-transparent text-[#BAB37A] px-6 py-3 rounded-full font-semibold flex items-center space-x-2 hover:bg-[#BAB37A] hover:text-white transition-colors border border-[#BAB37A]"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={scrollToProducts}
          >
            <span>Explore Our Products</span>
            <ArrowRight size={20} />
          </motion.button>
        </div>
        <div className="md:w-1/2">
          <div className="w-full h-0 pb-[75%] relative rounded-lg overflow-hidden">
            <img 
              src="/kabett-header.webp" 
              alt="Kabett products enhancing everyday life" 
              className="absolute top-0 left-0 w-full h-full object-cover"
            />
          </div>
        </div>
      </section>

      <section ref={productsRef} className="mb-24">
        <h2 className="text-4xl font-serif font-bold mb-8">Our Products</h2>
        <div className="grid md:grid-cols-3 gap-12">
          <div className="bg-blue-50 p-8 rounded-lg">
            <div className="flex justify-center mb-6">
              <img src="/mumma-care.png" alt="MummaCare Logo" className="w-32 h-32 rounded-full object-cover" />
            </div>
            <h3 className="text-2xl font-serif font-bold mb-4 text-center">MummaCare</h3>
            <p className="mb-6 text-center text-gray-600">Empowering mothers with comprehensive care and support throughout their journey.</p>
            <div className="text-center">
              <a href="https://mummacare.com" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">Visit MummaCare</a>
            </div>
          </div>
          <div className="bg-pink-50 p-8 rounded-lg">
            <div className="flex justify-center mb-6">
              <img src="/pranayama.png" alt="Pranayama Extension Logo" className="w-32 h-32 rounded-full object-cover" />
            </div>
            <h3 className="text-2xl font-serif font-bold mb-4 text-center">Pranayama Chrome Extension</h3>
            <p className="mb-6 text-center text-gray-600">Enhance your browsing experience with mindful breathing exercises.</p>
            <div className="text-center">
              <a href="https://chromewebstore.google.com/detail/epjichmldhceknjpoihgjkgccmllpbeg" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">Get the Extension</a>
            </div>
          </div>
          <div className="bg-yellow-50 p-8 rounded-lg">
            <div className="flex justify-center mb-6">
              <img src="/kabett-business-consultancy.webp" alt="Business Consultation Logo" className="w-32 h-32 rounded-full object-cover" />
            </div>
            <h3 className="text-2xl font-serif font-bold mb-4 text-center">Business Consultation</h3>
            <p className="mb-6 text-center text-gray-600">Expert guidance to take your business to the next level with our Small Entrepreneurship Solutions.</p>
            <div className="text-center">
              <a href="#business-consultation" onClick={() => setCurrentPage('consultation')} className="text-blue-600 hover:underline">Learn More</a>
            </div>
          </div>
        </div>
      </section>
    </>
  );

  return (
    <div className="min-h-screen bg-white font-sans flex flex-col">
      <Helmet>
        <link href="https://fonts.googleapis.com/css2?family=Playfair+Display:wght@700&family=Merriweather:wght@400;700&display=swap" rel="stylesheet" />
      </Helmet>
      <header className="container mx-auto px-8 py-6">
        <nav className="flex flex-wrap justify-between items-center">
          <div className="flex items-center space-x-3 cursor-pointer" onClick={() => {
            setCurrentPage('home');
            window.location.hash = '';
          }}>
            <img src="/kabett401.webp" alt="Kabett Logo" className="w-12 h-12" />
            <div className="text-3xl font-bold" style={{ fontFamily: "'Playfair Display', serif", letterSpacing: '1px' }}>Kabett</div>
          </div>
          <ul className="flex space-x-8 mt-4 md:mt-0">
            <li>
              <a 
                href="#business-consultation"
                className="text-gray-600 hover:text-gray-900 bg-transparent border-none cursor-pointer"
                onClick={() => setCurrentPage('consultation')}
              >
                Consultation
              </a>
            </li>
            <li>
              <a 
                href="#privacy-policy"
                className="text-gray-600 hover:text-gray-900 bg-transparent border-none cursor-pointer"
              >
                Privacy Policy
              </a>
            </li>
            <li>
              <a 
                href="#contact"
                className="text-gray-600 hover:text-gray-900 bg-transparent border-none cursor-pointer"
              >
                Contact
              </a>
            </li>
          </ul>
        </nav>
      </header>

      <main className="container mx-auto px-8 py-16 flex-grow">
        {currentPage === 'home' && <HomePage />}
        {currentPage === 'consultation' && <BusinessConsultationPage />}
        {currentPage === 'privacy' && <PrivacyPolicyPage />}
        {currentPage === 'contact' && <ContactPage />}
      </main>

      <footer className="bg-[#F3F1E4] py-8 mt-12">
        <div className="container mx-auto px-8">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <p className="text-[#BAB37A] font-semibold mb-4 md:mb-0">&copy; 2024 Kabett. All rights reserved.</p>
            <div className="flex space-x-4">
              <a href="#business-consultation" className="text-[#BAB37A] hover:text-[#9A9463]">Business Consultation</a>
              <a href="#privacy-policy" className="text-[#BAB37A] hover:text-[#9A9463]">Privacy Policy</a>
              <a href="#contact" className="text-[#BAB37A] hover:text-[#9A9463]">Contact</a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default App;